<template>
  <div class="bar-chart">
    <apexchart
      :options="chartOptions"
      :series="[{ data: data.map((item) => item.data) }]"
      :width="width"
      :height="height"
      :stroke="stroke"
    ></apexchart>
  </div>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";

export default {
  name: "BarChart",
  props: {
    width: {
      type: Number,
      default: 200,
    },
    height: {
      type: Number,
      default: 100,
    },
    stroke: {
      type: Number,
      default: 3,
    },
    data: {
      type: Array,
      required: true,
    },
  },
  components: {
    apexchart: VueApexCharts,
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          type: "bar",
          zoom: {
            enabled: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "30%", // Adjust the width of the bars here (in percentage)
          },
        },
        colors: ['#606060'],
        xaxis: {
          categories: this.data.map((item) => item.name),
          labels: {
            show: true, // Hide x-axis labels
          },
          style: {
            fontSize: "2px",
          },
        },

        yaxis: {
          show: true, // Hide y-axis labels
        },
        dataLabels: {
          enabled: false,
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
      };
    },
  },
};
</script>

<style scoped>
.bar-chart {
  width: 100%;
}
</style>

<script setup>
import { logos as logo } from "@/assets/radix-icons_component-3.svg";
import { onMounted, ref, computed, toRaw } from "vue";
import { useStore } from "vuex";
import EditComponent from "@/components/ContentBox/EditComponent.vue";

const store = useStore();
const items = computed(() => store.getters.items);

const props = defineProps({
  data: Object,
  isCollection: Boolean,
  isGlobalComponent: Boolean,
});

const comp = ref(props.data.componentName);

async function changeComponent() {
  if (comp.value == "Short Text" || comp.value == "Long Text")
    comp.value = "Text";
  if (comp.value == "Single Media" || comp.value == "Multiple Media")
    comp.value = "Media";
}

onMounted(async () => {
  await changeComponent();
  getIcon(props.data.componentName);
});

// export default {
//   name: 'GenericContainer',
//   components: {
//     logo, // Register the imported SVG component
//   },
// };

const icon = ref("");
const isEditModalOpen = ref(false);

const getIcon = async (type) => {
  if (type == "Long Text" || type == "Short Text")
    icon.value = require("@/assets/radix-icons_component-1.svg");
  else if (type == "Email")
    icon.value = require("@/assets/radix-icons_component-2.svg");
  else if (type == "Rich Text")
    icon.value = require("@/assets/radix-icons_component-3.svg");
  else if (type == "Password")
    icon.value = require("@/assets/radix-icons_component-4.svg");
  else if (type == "Number")
    icon.value = require("@/assets/radix-icons_component-6.svg");
  else if (type == "Enumeration")
    icon.value = require("@/assets/radix-icons_component-5.svg");
  else if (type == "RadioButton")
    icon.value = require("@/assets/radix-icons_component-5.svg");
  else if (type == "CheckBox")
    icon.value = require("@/assets/radix-icons_component-5.svg");
  else if (type == "Array")
    icon.value = require("@/assets/radix-icons_component-5.svg");
  else if (type == "DropDown")
    icon.value = require("@/assets/radix-icons_component-5.svg");
  else if (type == "Date")
    icon.value = require("@/assets/radix-icons_component-7.svg");
  else if (type.includes("Media"))
    icon.value = require("@/assets/radix-icons_component-8.svg");
  else if (type == "Boolean")
    icon.value = require("@/assets/radix-icons_component-9.svg");
  else if (type == "JSON")
    icon.value = require("@/assets/radix-icons_component-10.svg");
  else if (type == "Collections")
    icon.value = require("@/assets/radix-icons_component-11.svg");
};

const deleteComponent = async (id) => {
  let obj = items.value[0];
  obj = await deleteComponentById(obj, id);
  store.dispatch("updateItem", { index: 0, newItem: obj });
};

const deleteComponentById = async (dataObject, idToDelete) => {
  // Function to recursively search and delete by id
  const recursiveDelete = (components) => {
    return components.filter((component) => {
      // Check if the component has childComponents and recursively search
      if (component.childComponents) {
        component.childComponents = recursiveDelete(component.childComponents);
      }
      // Return false if the id matches, to filter out the object
      return component.id !== idToDelete;
    });
  };

  // Start the deletion from componentData array
  dataObject.componentData = recursiveDelete(dataObject.componentData);
  return dataObject;
};

const closeEditModal = () => {
  isEditModalOpen.value = false;
};

async function openEditModal() {
  isEditModalOpen.value = !isEditModalOpen.value;
}
</script>

<template>
  <div class="genericParent">
    <div class="leftComp">
      <img :src="icon" />
      <span class="contentType"> {{ props.data.label }} </span>
    </div>
    <span class="componentNameSpan"> {{ props.data.componentName }} </span>
    <span class="internalizationSpan">English(US), English(UK)</span>
    <span
      class="actionSpan"
      v-if="!props.isCollection && !props.isGlobalComponent"
    >
      <v-icon name="la-edit-solid" @click="openEditModal" />
      <v-icon
        class="trashIcon"
        @click="deleteComponent(props.data.id)"
        name="fa-trash-alt"
      />
    </span>
    <EditComponent
      v-if="isEditModalOpen"
      :data="props.data"
      :component="comp"
      :isOpen="isEditModalOpen"
      :close="openEditModal"
    />
  </div>
</template>

<style scoped>
.trashIcon {
  color: red;
}

.genericParent {
  display: flex;
  flex-direction: row;
  border: 1px solid;
  border-radius: 1rem;

  justify-content: space-between;
  /* font-size: 1.5rem; */
  font-weight: 400;
  /* padding: 0.5rem 2rem 0.5rem 1.5rem;
  margin:1rem; */

  padding: 0.5rem 2.5rem 0.5rem 1.5rem;
  margin: 1rem 0.5rem 1rem 0.5rem;
  align-items: center;
  color: #606060;
}
/* .componentNameSpan {
  margin: 0rem 0rem 0rem -1rem;
} */

.leftComp {
  display: flex;
  flex-direction: row;
  flex: 4;
  align-items: center;
}

.contentType {
  margin-left: 1rem;
}

/* .internalizationSpan {
  margin-right: -2rem;
} */

.componentParentSpan {
  flex: 2;
  text-align: center;
}
.componentNameSpan {
  flex: 2;
  text-align: center;
}

.internalizationSpan {
  flex: 3;
  text-align: center;
}
.actionSpan {
  flex: 1;
  text-align: center;
}
</style>

import api_request from "../../../libs/sendRequest/sendRequest";
import magicJson from "./../../../magicJson";

class ContentTypeBuilder {
  api_request = new api_request("/api/service");

  async getCollectionItems(_id) {
    try {
      magicJson.endpoint = "contentManager";
      magicJson.executor = "getCollectionItems";
      magicJson.data = { _id };
      const result = await this.api_request.sendRequest(magicJson);
      return result.data[0];
    } catch (error) {
      return error;
    }
  }

  async createCollectionItem(data, imagesData) {
    try {
      magicJson.endpoint = "contentManager";
      magicJson.executor = "createCollectionItem";
      magicJson.data = data;
      const result = await this.api_request.sendRequest(magicJson, imagesData);
      return result.data[0];
    } catch (error) {
      return error;
    }
  }

  async updateCollectionItem(data, imageData) {
    try {
      magicJson.endpoint = "contentManager";
      magicJson.executor = "updateCollectionItem";
      magicJson.data = data;
      const result = await this.api_request.sendRequest(magicJson, imageData);
      return result.data[0];
    } catch (error) {
      return error;
    }
  }

  async deleteCollectionItem(id) {
    try {
      magicJson.endpoint = "contentManager";
      magicJson.executor = "deleteCollectionItem";
      magicJson.data = [{ id }];
      const result = await this.api_request.sendRequest(magicJson);
      return result.data[0];
    } catch (error) {
      return error;
    }
  }
}

export default ContentTypeBuilder;

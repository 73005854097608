import axios from "axios";
import magicJson from "../../magicJson";
import api_request from "../../libs/sendRequest/sendRequest";

const userToken = () => {
  const token = localStorage.getItem("token");
  if (token) {
    return token;
  }
  return null;
};

const jsonParser = (dataString) => {
  if (dataString) return JSON.parse(dataString);
  return null;
};

const getSidebar = async () => {
  const apiRequest = new api_request("/api/service");
  try {
    magicJson.endpoint = "sidebar";
    magicJson.executor = "getSidebar";

    const response = await apiRequest.sendRequest(magicJson);
    return response;
  } catch (error) {
    return error;
  }
};

function generateRandomPassword() {
  const specialSymbols = "!@#$%^&*()_+[]{}|;:,.<>?";
  const numbers = "0123456789";

  const randomSymbol =
    specialSymbols[Math.floor(Math.random() * specialSymbols.length)];

  const randomNumber = numbers[Math.floor(Math.random() * numbers.length)];

  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  let randomChars = "";
  for (let i = 0; i < 4; i++) {
    randomChars += characters[Math.floor(Math.random() * characters.length)];
  }

  const password = randomChars + randomSymbol + randomNumber;

  return password;
}

// function to get admin access_token from keycloak.
const tokenService = async (req, res) => {
  const keycloakConfig = {
    baseUrl: import.meta.env.VITE_BASE_URL,
    realmName: import.meta.env.VITE_REALM_NAME,
    clientId: import.meta.env.VITE_CLIENT_ID,
    clientSecret: import.meta.env.VITE_CLIENT_SECRET,
    grantType: import.meta.env.VITE_GRANT_TYPE,
  };
  try {
    const tokenResponse = await axios.post(
      `${keycloakConfig.baseUrl}/realms/${keycloakConfig.realmName}/protocol/openid-connect/token`,
      new URLSearchParams({
        grant_type: keycloakConfig.grantType,
        client_id: keycloakConfig.clientId,
        client_secret: keycloakConfig.clientSecret,
      })
    );
    // save the access token for further use.
    return tokenResponse.data;
  } catch (error) {
    console.log("error", error);
    return error;
  }
};

const formatFileSize = (bytes) => {
  if (bytes === 0) return '0 Byte';
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
};

export {
  userToken,
  jsonParser,
  getSidebar,
  generateRandomPassword,
  tokenService,
  formatFileSize
};

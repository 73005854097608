<template>
  <div class="donut-chart">
    <apexchart
      :width="width"
      :height="height"
      :options="chartOptions"
      :series="chartData"
    ></apexchart>
  </div>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";

export default {
  name: "DonutChart",
  props: {
    width: {
      type: Number,
      default: 220,
    },
    height: {
      type: Number,
      default: 180,
    },
    data: {
      type: Array,
      required: true,
    },
    customPlotOptions: {
      type: Object,
    },
  },
  components: {
    apexchart: VueApexCharts,
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          type: "donut",
        },
        colors: this.getColors(),
        labels: this.data.map((region) => region.label),
        legend: {
          show: false,
        },
        plotOptions: this.customPlotOptions
          ? this.customPlotOptions
          : {
              pie: {
                donut: {
                  size: "45%",
                  labels: {
                    total: {
                      show: false,
                      label: "Total",
                      color: "#373d3f",
                    },
                  },
                },
              },
            },
      };
    },
    chartData() {
      return this.data.map((region) => region.value);
    },
  },
  methods: {
    getColors() {
      const colorMap = {};

      this.data.forEach((entry, index) => {
        colorMap[entry.label] = entry.color;
      });

      return this.data.map((entry) => colorMap[entry.label]);
    },
  },
};
</script>

<style scoped>
.donut-chart {
  width: 100%;
  /* height: 100%; */
}
</style>

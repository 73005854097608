export const FormatNumberMixin = {
  methods: {
    formatNumber(num) {
      const format = (number, divisor, suffix) => {
        const result = number / divisor;
        // Check if the decimal part is zero
        return result % 1 === 0 ? result.toFixed(0) + suffix : result.toFixed(1) + suffix;
      };

      if (num >= 1e9) {
        return format(num, 1e9, "b");
      }
      // Check if the num count is greater than or equal to 1 million
      else if (num >= 1e6) {
        return format(num, 1e6, "m");
      }
      // Check if the num count is greater than or equal to 1 thousand
      else if (num >= 1e3) {
        return format(num, 1e3, "k");
      }
      // For counts less than 1000
      else {
        return num.toString();
      }
    },
  },
};

export const SearchMixin = {
  methods: {
    handleSearch(searchPerformed, searchQuery, filteredList, list) {
      const searchTerm = searchQuery.toLowerCase().trim();
      searchPerformed = !!searchTerm;

      if (!searchTerm) {
        filteredList = list;
      } else {
        filteredList = list.filter((item) =>
          Object.keys(item).some((key) => {
            return (
              key !== "_id" &&
              item[key] &&
              (item[key].toString().toLowerCase().includes(searchTerm) ||
                this.formatDate(item["createdAt"]).toLowerCase().includes(searchTerm))
            );
          })
        );
      }

      return {
        searchPerformed,
        filteredList,
      };
    },

    formatDate(date) {
      if (date) {
        const options = { month: "long", day: "numeric", year: "numeric" };
        return new Date(date).toLocaleDateString(undefined, options);
      }
      return "-";
    },
  },
};

<!-- src/components/KeywordChip.vue -->
<template>
    <div class="keyword-chip-container">
      <div class="chips">
        <div
          v-for="keyword in keywords"
          :key="keyword"
          :class="chipClasses"
          @click="handleClick(keyword)"
        >
          <span>{{ keyword }}</span>
          <button
            v-if="removable"
            @click.stop="handleRemove(keyword)"
            class="remove-button"
          >
            x
          </button>
        </div>
      </div>
      <input
        v-model="newKeyword"
        @keyup.enter="addKeyword"
        placeholder="Add a keyword"
      />
    </div>
  </template>
  
  <script setup>
  import { ref, computed } from 'vue';
  
  // Props
  const props = defineProps({
    keywords: {
      type: Array,
      default: () => []
    },
    removable: {
      type: Boolean,
      default: true
    }
  });
  
  // Emits
  const emit = defineEmits(['update:keywords', 'remove', 'click']);
  
  // State
  const newKeyword = ref('');
  
  // Computed classes
  const chipClasses = computed(() => ({
    'keyword-chip': true,
    'keyword-chip--removable': props.removable
  }));
  
  // Methods
  const handleClick = (keyword) => {
    emit('click', keyword);
  };
  
  const handleRemove = (keyword) => {
    emit('remove', keyword);
  };
  
  const addKeyword = () => {
    const keyword = newKeyword.value.trim();
    if (keyword && !props.keywords.includes(keyword)) {
      emit('update:keywords', [...props.keywords, keyword]);
      newKeyword.value = '';
    }
  };
  </script>
  
  <style scoped>
  .keyword-chip-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .chips {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
  
  .keyword-chip {
    display: inline-flex;
    align-items: center;
    padding: 4px 8px;
    margin: 4px;
    border-radius: 16px;
    background-color: var(--chip-background, #e0e0e0);
    color: #333;
    font-size: 14px;
    cursor: pointer;
  }
  
  .keyword-chip--removable {
    padding-right: 8px;
  }
  
  .remove-button {
    border: none;
    background: transparent;
    color: #ff0000;
    cursor: pointer;
    font-size: 16px;
    margin-left: 4px;
  }
  
  input {
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
  }
  
  input:focus {
    border-color: #007bff;
  }
  </style>
  
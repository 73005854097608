import api_request from "./../../../libs/sendRequest/sendRequest";
import magicJson from "./../../../magicJson";

class candidatesService {
  apiRequest = new api_request("/api/service");

  createCandidate = async (data) => {
    try {
      magicJson.endpoint = "apply";
      magicJson.executor = "createCandidate";
      magicJson.data = [data];
      const result = await this.apiRequest.sendRequest(magicJson);
      return result;
    } catch (error) {
      return error;
    }
  };

  createCandidateProfile = async (data) => {
    try {
      magicJson.endpoint = "apply";
      magicJson.executor = "createCandiadateProfile";
      magicJson.data = [data];
      const result = await this.apiRequest.sendRequest(magicJson);
      return result;
    } catch (error) {
      return error;
    }
  };
  
  getCandidateProfileById = async (data) => {
    try {
      magicJson.endpoint = "apply";
      magicJson.executor = "getCandidateProfileById";
      magicJson.data = [data];
      const result = await this.apiRequest.sendRequest(magicJson);
      return result;
    } catch (error) {
      return error;
    }
  };

  getAllCandidate = async () => {
    try {
      magicJson.endpoint = "apply";
      magicJson.executor = "getAllCandidate";
      const result = await this.apiRequest.sendRequest(magicJson);
      return result.data;
    } catch (error) {
      return error;
    }
  };

  getCandidateById = async (id) => {
    try {
      magicJson.endpoint = "apply";
      magicJson.executor = "getCandidateById";
      magicJson.data = [{ id }];
      const result = await this.apiRequest.sendRequest(magicJson);
      return result.data[0];
    } catch (error) {
      return error;
    }
  };

  getCandidatesByJobCode = async (jobCode) => {
    try {
      magicJson.endpoint = "apply";
      magicJson.executor = "getCandidatesByJobCode";
      magicJson.data = [{ jobCode }];
      const result = await this.apiRequest.sendRequest(magicJson);
      return result.data;
    } catch (error) {
      return error;
    }
  };

  //   backend function is left for this
  updateCandidate = async (data, jobId) => {
    try {
      magicJson.endpoint = "apply";
      magicJson.executor = "updateCandidate";
      magicJson.data = [{ data, id: jobId }];
      const result = await this.apiRequest.sendRequest(magicJson);
      return result;
    } catch (error) {
      return error;
    }
  };

  deleteCandidate = async (data) => {
    try {
      magicJson.endpoint = "apply";
      magicJson.executor = "deleteCandidate";
      magicJson.data = [data];
      const result = await this.apiRequest.sendRequest(magicJson);
      return result;
    } catch (error) {
      return error;
    }
  };
}

export default candidatesService;

import magicJson from "../../../magicJson";
import api_request from "../../../libs/sendRequest/sendRequest";

class CountryStateCityService {
  apiRequest = new api_request("/api/service");

  getAllCountries = async () => {
    try {
      magicJson.endpoint = "countryStateCity";
      magicJson.executor = "getAllCountries";
      // applied isCached to use redis.
      // magicJson.isCached = true;
      const response = await this.apiRequest.sendRequest(magicJson);
      return response.data[0];
    } catch (error) {
      return error;
    }
  };

  getStatesByCountryName = async (countryName) => {
    try {
      magicJson.endpoint = "countryStateCity";
      magicJson.executor = "getStatesByCountryName";
      // applied isCached to use redis.
      // magicJson.isCached = true;
      magicJson.data = [{ countryName }];
      const response = await this.apiRequest.sendRequest(magicJson);
      return response.data[0];
    } catch (error) {
      return error;
    }
  };

  getCitiesByStateAndCountryName = async (countryName, stateName) => {
    try {
      magicJson.endpoint = "countryStateCity";
      magicJson.executor = "getCitiesByStateAndCountryName";
      // applied isCached to use redis.
      // magicJson.isCached = true;
      magicJson.data = [{ countryName, stateName }];
      const response = await this.apiRequest.sendRequest(magicJson);
      return response.data[0];
    } catch (error) {
      return error;
    }
  };

  getCountryAdditionalInfo = async (countryName) => {
    try {
      magicJson.endpoint = "countryStateCity";
      magicJson.executor = "getCountryAdditionalInfo";
      // applied isCached to use redis.
      // magicJson.isCached = true;
      magicJson.data = [{ countryName }];
      const response = await this.apiRequest.sendRequest(magicJson);
      return response.data[0];
    } catch (error) {
      return error;
    }
  };
}

export default CountryStateCityService;

import api_request from "./../../../libs/sendRequest/sendRequest";
import magicJson from "./../../../magicJson";

class formsService {
  apiRequest = new api_request("/api/service");

  createForms = async (data) => {
    try {
      magicJson.endpoint = "forms";
      magicJson.executor = "createForms";
      magicJson.data = [data];
      const result = await this.apiRequest.sendRequest(magicJson);
      return result;
    } catch (error) {
      return error;
    }
  };

  getForms = async () => {
    try {
      magicJson.endpoint = "forms";
      magicJson.executor = "getForms";
      const result = await this.apiRequest.sendRequest(magicJson);
      return result.data;
    } catch (error) {
      return error;
    }
  };

  getFormsById = async (id) => {
    try {
      magicJson.endpoint = "forms";
      magicJson.executor = "getFormsById";
      magicJson.data = [{ id }];
      const result = await this.apiRequest.sendRequest(magicJson);
      return result.data[0];
    } catch (error) {
      return error;
    }
  };

  updateForms = async (data, jobId) => {
    try {
      magicJson.endpoint = "forms";
      magicJson.executor = "updateForms";
      magicJson.data = [{ data, id: jobId }];
      const result = await this.apiRequest.sendRequest(magicJson);
      return result;
    } catch (error) {
      return error;
    }
  };

  deleteForms = async (data) => {
    try {
      magicJson.endpoint = "forms";
      magicJson.executor = "deleteForms";
      magicJson.data = [data];
      const result = await this.apiRequest.sendRequest(magicJson);
      return result;
    } catch (error) {
      return error;
    }
  };

  getContactUsResponses = async (data) => {
    try {
      magicJson.endpoint = "forms";
      magicJson.executor = "getContactUsResponses";
      const result = await this.apiRequest.sendRequest(magicJson);
      return result.data;
    } catch (error) {
      return error;
    }
  };

  getAllSubscribers = async () => {
    try {
      magicJson.endpoint = "forms";
      magicJson.executor = "getAllSubscribers";
      const result = await this.apiRequest.sendRequest(magicJson);
      return result.data;
    } catch (error) {
      return error;
    }
  };
}

export default formsService;

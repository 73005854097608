<script setup>
import { defineProps, defineEmits, onMounted,ref} from "vue";

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  type: {
    type: String,
    default: "text",
  },
  modelValue: {
    type: [String, Boolean, Array], // Support for checkbox
    default: undefined,
  },
  label: {
    type: String,
    default: "Label comes here",
  },
  required: {
    type: Boolean,
    default: false,
  },
  minLength: {
    type: Number,
    default: null,
  },
  maxLength: {
    type: Number,
    default: null,
  },
  placeholder: {
    type: String,
    default: "Enter here...",
  },
  customComponent: {
    type: Object, // Expect a component
    default: null,
  },
  pattern: {
    type: String,
    default: null,
  },
  options: {
    type: Array,
    default: [],
  },
  isGlobal: {
    type: Boolean,
  },
});
const emit = defineEmits(["update:modelValue","update:options"]);
const value = ref(props.modelValue);
const updateValue = (event) => {
  value.value = event.target.value;

  const updatedOptions = value.value.split('\n').filter(line=>line.trim());
  emit("update:options", updatedOptions);
};

onMounted(() => {
  let str=props?.options;
  if(str){
  str=str.join('\n');
  console.log(str);
  value.value=str;
  }
  else{
    value.value="";
  }
});

</script>

<template>
  <div class="field-preview">
    <label :for="id">{{ label }}
      <span class="required" v-if="required">(Required)</span></label>

      <!-- textarea(:style="{width: props.width}" class="textAreaInput" :placeholder="props.placeholder" :value="value" :rows="5" @input="updateValue" :required="props.required") -->
      <textarea class="textAreaInput" :id="id" @input="updateValue" :value="value" :disabled="isGlobal"></textarea>
  </div>
</template>

<style lang="scss" scoped>
.field-preview {
  width: 98%;
  display: flex;
  flex-direction: column;
  gap: 3px;

  label {
    font-weight: bold;

    .required {
      color: #606060;
      font-weight: 300;
      font-size: smaller;
      margin-left: 5px;
    }
  }

  input,
  textarea {
    width: auto !important;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    outline: none;
    color: black;

    padding: 10px;
  }

  input::placeholder,
  textarea::placeholder {
    color: #999;
  }

  .char-info {
    font-size: 12px;
    color: #666;
    display: flex;
    justify-content: space-between;
  }
}
</style>

import { utils, write } from "xlsx";

export const ExportMixin = {
  methods: {
    handleExportMixin(data, columns, fileName) {
      try {
        // Check if there is data available for export
        if (data.length === 0) {
          alert("There is nothing to export.");
          return;
        }

        // Extract data based on provided columns
        const formattedData = data.map((item) => {
          const formattedItem = {};
          columns.forEach((column) => {
            let value = item[column.key];

            // Check if the value is an object and has the 'url' property
            if (value && typeof value === "object" && value.url) {
              value = value.url;
            }

            formattedItem[column.label] = value;
          });
          return formattedItem;
        });

        // Create a new worksheet
        const ws = utils.json_to_sheet(formattedData);

        // Create a new workbook
        const wb = utils.book_new();

        // Add the worksheet to the workbook
        utils.book_append_sheet(wb, ws, "Sheet1");

        // Generate Excel file as a binary string
        const excelBinaryData = write(wb, { type: "binary", bookType: "xlsx" });

        // Convert the binary string to a Blob object
        const blob = new Blob([this.binaryToArrayBuffer(excelBinaryData)], {
          type: "application/octet-stream",
        });

        // Create a temporary anchor element
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName || "export.xlsx";

        // Trigger a click event on the anchor element to download the Excel file
        link.click();

        // Cleanup
        window.URL.revokeObjectURL(link.href);
      } catch (error) {
        console.error("handleExportMixin: Error while exporting data:", error);
      }
    },

    binaryToArrayBuffer(s) {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    },
  },
};

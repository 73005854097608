<script setup>
import { ref, defineExpose } from "vue";
import RadioComponent from "./RadioComponent"
import TextComponent from './TextComponent'
import * as Form from "./FormComponent";
import "../../scss/_customComponents.scss";

const formRefs = ref([]);

const formJSON = [
  {
    component: TextComponent,
    id: 'input1',
    type: "text",
    label: "Name",
    value: "",
    placeholder: "Enter value",
    options: []
  },
  {
    component: RadioComponent,
    id: 'input2', // Ensure IDs are unique
    label: "Options",
    value: "",
    type: "radio",
    placeholder: "Enter value",
    options: [
      {
        title: "Short Text",
        description: "Best for titles, names, link(URL). It also enables exact search on the field.",
      },
      {
        title: "Long Text",
        description: "Best for descriptions, biographies. Exact search is disabled.",
      },
    ]
  }
];

const handleSubmit = () => {
  const formData = formJSON.map((item, index) => {
    const componentProps = formRefs.value[index]?.getData();
    if (componentProps) {
      return {
        id: item.id,
        label: item.label,
        type: item.type,
        value: componentProps.value,
        placeholder: item.placeholder,
        options: item.options || componentProps.options
      };
    }
    return {}; // Fallback if no componentProps
  });

  console.log(formData);
}

defineExpose({
  handleSubmit
});
</script>

<template>
  <form @submit.prevent="handleSubmit" class="contentBuilderForm">
    <component v-for="(item, index) in formJSON" :key="index" :is="item.component" :id="item.id" :type="item.type"
      :ref="el => formRefs[index] = el" :label="item.label" v-model="item.value" :placeholder="item.placeholder"
      :options="item.options" />
  </form>
</template>

import api_request from "../../../libs/sendRequest/sendRequest";
import magicJson from "../../../magicJson";

class googleAnalyticsService {
  apiRequest = new api_request("/api/service");

  getAnalyticsData = async () => {
    try {
      magicJson.endpoint = "googleAnalytics";
      magicJson.executor = "getAnalyticsData";
      magicJson.data = [];
      const result = await this.apiRequest.sendRequest(magicJson);
      return result;
    } catch (error) {
      return error;
    }
  };
}

export default googleAnalyticsService;

import api_request from "./../../../libs/sendRequest/sendRequest";
import magicJson from "./../../../magicJson";

class resourcesService {
  apiRequest = new api_request("/api/service");

  createResources = async (data, image) => {
    try {
      magicJson.endpoint = "resources";
      magicJson.executor = "createResources";
      magicJson.data = [data];
      const result = await this.apiRequest.sendRequest(magicJson, image);
      return result;
    } catch (error) {
      return error;
    }
  };

  getResources = async (data) => {
    try {
      magicJson.endpoint = "resources";
      magicJson.executor = "getResources";
      magicJson.data = [data];
      const result = await this.apiRequest.sendRequest(magicJson);
      return result.data;
    } catch (error) {
      return error;
    }
  };

  getResourcesById = async (id) => {
    try {
      magicJson.endpoint = "resources";
      magicJson.executor = "getResourcesById";
      magicJson.data = [{ id }];
      const result = await this.apiRequest.sendRequest(magicJson);
      return result.data[0];
    } catch (error) {
      return error;
    }
  };

  getResourcesByCategory = async (parentCategory, category) => {
    try {
      magicJson.endpoint = "resources";
      magicJson.executor = "getResourcesByCategory";
      magicJson.data = [{ parentCategory, category }];
      const result = await this.apiRequest.sendRequest(magicJson);
      return result.data[0];
    } catch (error) {
      return error;
    }
  };

  updateResources = async (data, jobId, image) => {
    try {
      magicJson.endpoint = "resources";
      magicJson.executor = "updateResources";
      magicJson.data = [{ data, id: jobId }];
      const result = await this.apiRequest.sendRequest(magicJson, image);
      return result;
    } catch (error) {
      return error;
    }
  };

  deleteResources = async (id) => {
    try {
      magicJson.endpoint = "resources";
      magicJson.executor = "deleteResources";
      magicJson.data = [{ id }];
      const result = await this.apiRequest.sendRequest(magicJson);
      return result;
    } catch (error) {
      return error;
    }
  };

  getAnalyticsData = async () => {
    try {
      magicJson.endpoint = "googleAnalytics";
      magicJson.executor = "getAnalyticsData";
      const result = await this.apiRequest.sendRequest(magicJson);
      return result.data;
    } catch (error) {
      return error;
    }
  };

  createCategory = async (data, image) => {
    try {
      magicJson.endpoint = "resourceCategories";
      magicJson.executor = "createResourceCategory";
      magicJson.data = [data];
      const result = await this.apiRequest.sendRequest(magicJson, image);
      return result.data;
    } catch (error) {
      return error;
    }
  };

  updateCategory = async (data, categoryId, image) => {
    try {
      magicJson.endpoint = "resourceCategories";
      magicJson.executor = "updateResourceCategory";
      magicJson.data = [{ data, id: categoryId }];
      const result = await this.apiRequest.sendRequest(magicJson, image);
      return result.data;
    } catch (error) {
      return error;
    }
  };

  getCategories = async (data) => {
    try {
      magicJson.endpoint = "resourceCategories";
      magicJson.executor = "getCategories";
      magicJson.data = [data];
      const result = await this.apiRequest.sendRequest(magicJson);
      return result.data;
    } catch (error) {
      return error;
    }
  };

  updateMultipleCategories = async (data, categoryIds) => {
    try {
      magicJson.endpoint = "resourceCategories";
      magicJson.executor = "deleteCategories";
      magicJson.data = [{ data, arr: categoryIds }];
      const result = await this.apiRequest.sendRequest(magicJson);
      return result;
    } catch (error) {
      return error;
    }
  };

  isCategoryExisiting = async (data) => {
    try {
      magicJson.endpoint = "resourceCategories";
      magicJson.executor = "isCategoryExisiting";
      magicJson.data = [data];
      const result = await this.apiRequest.sendRequest(magicJson);
      return result.data;
    } catch (error) {
      return error;
    }
  };

  updateCategoryDetails = async (data) => {
    try {
      magicJson.endpoint = "resourceCategories";
      magicJson.executor = "updateCategoryDetails";
      magicJson.data = [data];
      const result = await this.apiRequest.sendRequest(magicJson);
      return result.data;
    } catch (error) {
      return error;
    }
  };

  updateParentCategoryDetails = async (data) => {
    try {
      magicJson.endpoint = "resourceCategories";
      magicJson.executor = "updateParentCategoryDetails";
      magicJson.data = [data];
      const result = await this.apiRequest.sendRequest(magicJson);
      return result.data;
    } catch (error) {
      return error;
    }
  };
}

export default resourcesService;

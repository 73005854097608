import api_request from "../../../libs/sendRequest/sendRequest";
import magicJson from "../../../magicJson";

class accessControlService {
  apiRequest = new api_request("/api/service");

  createRole = async (data) => {
    try {
      magicJson.endpoint = "accessControl";
      magicJson.executor = "createRole";
      magicJson.data = [data];
      const result = await this.apiRequest.sendRequest(magicJson);
      return result;
    } catch (error) {
      return error;
    }
  };

  getRoles = async () => {
    try {
      magicJson.endpoint = "accessControl";
      magicJson.executor = "getRoles";
      const result = await this.apiRequest.sendRequest(magicJson);
      return result;
    } catch (error) {
      return error;
    }
  };

  editRole = async (data) => {
    try {
      magicJson.endpoint = "accessControl";
      magicJson.executor = "editRole";
      magicJson.data = [data];
      const result = await this.apiRequest.sendRequest(magicJson);
      return result;
    } catch (error) {
      return error;
    }
  };

  updateRoleLicenses = async (data) => {
    try {
      magicJson.endpoint = "accessControl";
      magicJson.executor = "updateRoleLicenses";
      magicJson.data = [data];
      const result = await this.apiRequest.sendRequest(magicJson);
      return result;
    } catch (error) {
      return error;
    }
  };

  deleteRole = async (data) => {
    try {
      magicJson.endpoint = "accessControl";
      magicJson.executor = "deleteRole";
      magicJson.data = [data];
      const result = await this.apiRequest.sendRequest(magicJson);
      return result;
    } catch (error) {
      return error;
    }
  };

  getUsers = async () => {
    try {
      magicJson.endpoint = "accessControl";
      magicJson.executor = "getUsers";
      const result = await this.apiRequest.sendRequest(magicJson);
      return result;
    } catch (error) {
      return error;
    }
  };

  updateUserLicenses = async (data) => {
    try {
      magicJson.endpoint = "accessControl";
      magicJson.executor = "updateUserLicenses";
      magicJson.data = [data];
      const result = await this.apiRequest.sendRequest(magicJson);
      return result;
    } catch (error) {
      return error;
    }
  };

  reassignRole = async (data) => {
    try {
      magicJson.endpoint = "accessControl";
      magicJson.executor = "reassignRole";
      magicJson.data = [data];
      const result = await this.apiRequest.sendRequest(magicJson);
    } catch (error) {
      return error;
    }
  }
}

export default accessControlService;

import api_request from "./../../../libs/sendRequest/sendRequest";
import magicJson from "./../../../magicJson";

class CompanyService {
  apiRequest = new api_request("/api/service");

  createMember = async (data, image) => {
    try {
      magicJson.endpoint = "company";
      magicJson.executor = "createMember";
      magicJson.data = [data];
      const result = await this.apiRequest.sendRequest(magicJson, image);
      return result;
    } catch (error) {
      return error;
    }
  };

  getAllMember = async () => {
    try {
      magicJson.endpoint = "company";
      magicJson.executor = "getAllMember";
      const result = await this.apiRequest.sendRequest(magicJson);
      return result.data;
    } catch (error) {
      return error;
    }
  };

  getMemberById = async (id) => {
    try {
      magicJson.endpoint = "company";
      magicJson.executor = "getMemberById";
      magicJson.data = [{ id }];
      const result = await this.apiRequest.sendRequest(magicJson);
      return result.data[0];
    } catch (error) {
      return error;
    }
  };

  getMemberByEmployeeCode = async (employeeCode) => {
    try {
      magicJson.endpoint = "company";
      magicJson.executor = "getMemberByEmployeeCode";
      magicJson.data = [{ employeeCode }];
      const result = await this.apiRequest.sendRequest(magicJson);
      return result.data[0];
    } catch (error) {
      return error;
    }
  };

  getMemberByEmail = async (email) => {
    try {
      magicJson.endpoint = "company";
      magicJson.executor = "getMemberByEmail";
      magicJson.data = [{ email }];
      const result = await this.apiRequest.sendRequest(magicJson);
      return result.data[0].status === 200
        ? result.data[0].response
        : undefined;
    } catch (error) {
      return error;
    }
  };

  getMemberByPhone = async (phone) => {
    try {
      magicJson.endpoint = "company";
      magicJson.executor = "getMemberByPhone";
      magicJson.data = [{ phone }];
      const result = await this.apiRequest.sendRequest(magicJson);
      return result.data[0].status === 200
        ? result.data[0].response
        : undefined;
    } catch (error) {
      return error;
    }
  };

  updateMember = async (data, image, memberID) => {
    try {
      magicJson.endpoint = "company";
      magicJson.executor = "updateMember";
      magicJson.data = [{ data, id: memberID }];
      const result = await this.apiRequest.sendRequest(magicJson, image);
      return result;
    } catch (error) {
      return error;
    }
  };

  updateMultipleMembers = async (data, jobIds) => {
    try {
      magicJson.endpoint = "company";
      magicJson.executor = "updateMultipleMembers";
      magicJson.data = [{ data, arr: jobIds }];
      const result = await this.apiRequest.sendRequest(magicJson);
      return result;
    } catch (error) {
      return error;
    }
  };

  deleteMember = async (data) => {
    try {
      magicJson.endpoint = "company";
      magicJson.executor = "deleteMember";
      magicJson.data = [data];
      const result = await this.apiRequest.sendRequest(magicJson);
      return result;
    } catch (error) {
      return error;
    }
  };
}

// export default new CompanyService();
export default CompanyService;
